<div class="l-form">
  <label *ngIf="label"> {{ label }}</label>
  <span>
    <ng-container *ngIf="require">
      <span class="c-require">必須</span>
    </ng-container>
  </span>
  <div class="l-form__content">
    <input
      #text
      [type]="type"
      [value]="control.value"
      [placeholder]="placeholder"
      (input)="onChange(text.value)"
      (blur)="onTouched()"
      required
      [ngClass]="{
        'c-error': control?.invalid && (control?.dirty || control?.touched)
      }"
    />
    <div class="l-help" *ngIf="helpText">
      <p>{{ helpText }}</p>
    </div>
    <div class="l-error">
      <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
        <div class="error-messege" *ngIf="control.errors?.email">
          有効なメールアドレスで入力してください。
        </div>
        <div class="error-messege" *ngIf="control?.errors?.required">
          {{ label }}を入力してください。
        </div>
        <div class="error-messege" *ngIf="control?.errors?.notMatchPassword">
          パスワードが一致しません。
        </div>
        <div class="error-messege" *ngIf="control?.errors?.notMatchKana">
          カタカナで入力してください。
        </div>
        <div *ngIf="control?.errors?.maxlength" class="error-messege">
          {{ maxValue }}文字以内で入力してください
        </div>
      </div>
    </div>
  </div>
</div>
