<div class="l-footer">
  <div class="l-footer__top">
    <img
      class="c-pc"
      alt="日中サービス支援型 重度障がい者グループホーム笑恵"
      src="./../../../../assets/img/logo-header.png"
    />
    <img
      class="c-sp"
      alt="日中サービス支援型 重度障がい者グループホーム笑恵"
      src="./../../../../assets/img/logo-footer-sp.png"
    />
    <div class="l-footer__top__contact">
      <span class="l-footer__top__contact__tel">
        <img src="./../../../../assets/img/move-tel.png" alt="" />
        072-451-2050</span
      >
      <span>（受付時間 9：00～18：00）</span>
      <span class="l-footer__top__contact__fax">FAX 072-451-2055</span>
    </div>
  </div>
  <div class="l-footer__contact">
    <span class="l-footer__contact__label">入居や見学の問い合わせはこちら</span>
    <a href="/contact"
      ><button class="l-footer__contact__button">問い合わせフォーム</button></a
    >
  </div>

  <ul class="l-footer__menu">
    <li><a href="/">トップページ</a></li>
    <li><a href="/facility">施設案内</a></li>
    <li><a href="/about">会社概要</a></li>
    <li><a href="/contact">問い合わせ</a></li>
    <li class="l-footer__menu__sns">
      <a href="https://www.instagram.com/emu.house_2023/" target="_brank">
        <img src="./../../../../assets/img/instagram.png" alt="Instagram" />
      </a>
      <a
        href="https://www.facebook.com/people/%E9%87%8D%E5%BA%A6%E9%9A%9C%E3%81%8C%E3%81%84%E8%80%85%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%83%9B%E3%83%BC%E3%83%A0%E7%AC%91%E6%81%B5/100084292812973/"
        target="_brank"
      >
        <img src="./../../../../assets/img/facebook.png" alt="Facebook" />
      </a>
    </li>
  </ul>

  <div class="l-footer__copyright">
    <p>©2023 グループホーム笑恵</p>
  </div>
</div>
