import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {
  ParamMap,
  Event,
  NavigationEnd,
  RouterEvent,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { combineLatest, from } from 'rxjs';
const moment = require('moment');
import { environment, client } from './../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss'],
})
export class BlogContentComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(DOCUMENT) private dom: any
  ) {
    this.router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        this.createCanonicalUrl(`${environment.baseURL}${e.url}`);
      });
  }

  public blog = {
    publishedAt: '',
    title: '',
    content: '',
    eyecatch: { url: '' },
  };

  public categoryList: {
    contents: [
      {
        name: '';
        id: '';
      }
    ];
  };
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      combineLatest(this.getCategoryList(), this.blogContent(id)).subscribe(
        ([category, content]) => {}
      );
    });
  }

  public format(date: string, formatValue: string): any {
    return moment(date).format(formatValue);
  }

  public formatDate(date: string): any {
    return this.format(date, 'YYYY年MM月DD日');
  }

  public blogContent(id: string) {
    return from(
      client
        .get({
          endpoint: 'blogs',
          contentId: id,
        })
        .then((res) => {
          res.content = this.sanitizer.bypassSecurityTrustHtml(res.content);
          this.blog = res;
          console.log(res.title);
          this.title.setTitle(
            res.title + ' | 日中サービス支援型 重度障がい者グループホーム笑恵'
          );
          this.meta.addTag({
            name: 'description',
            content: res.content.changingThisBreaksApplicationSecurity.replace(
              /<("[^"]*"|'[^']*'|[^'">])*>|\n/g,
              ''
            ),
          });
        })
    );
  }

  private createCanonicalUrl(url?: string) {
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  public getCategoryList() {
    return from(
      client
        .get({
          endpoint: 'categories',
          queries: { orders: '-publishedAt', limit: 100, offset: 0 },
        })
        .then((data) => {
          console.log(data);
          this.categoryList = data;
        })
    );
  }
}
