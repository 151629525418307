<div class="l-header" [class.--humberger]="isHumberger">
  <a href="/" class="l-header__logo" oncontextmenu="return false;">
    <h1>
      <img
        class="c-pc"
        alt="日中サービス支援型 重度障がい者グループホーム笑恵"
        oncontextmenu="return false;"
        onselectstart="return false;"
        onmousedown="return false;"
        src="./../../../../assets/img/logo-header.png"
      />
      <img
        class="c-sp"
        alt="日中サービス支援型 重度障がい者グループホーム笑恵"
        oncontextmenu="return false;"
        onselectstart="return false;"
        onmousedown="return false;"
        src="./../../../../assets/img/logo-header-sp.png"
      />
    </h1>
  </a>

  <div class="l-header__contact">
    <a class="c-contact" href="/contact">
      <img src="./../../../../assets/img/mail.png" alt="お問い合わせ" />
      メールでお問い合わせ
    </a>
  </div>
  <div class="l-header__tel">
    <a class="c-tel" href="tel:072-451-2050">
      <img src="./../../../../assets/img/tel.png" alt="" />
      <span>072-451-2050</span>
    </a>
  </div>
  <ul class="l-header__list">
    <li>
      <a class="" href="/">
        <span class="c-list-title">トップページ</span>
      </a>
    </li>
    <li>
      <a class="" href="/facility">
        <span class="c-list-title">施設案内</span>
      </a>
    </li>

    <li>
      <a class="" href="/about">
        <span class="c-list-title">会社概要</span>
      </a>
    </li>

    <!-- <li>
      <a class="" href="/blog-list">
        <span class="c-list-title">ブログ</span>
      </a>
    </li> -->

    <li>
      <a class="" href="/contact">
        <span class="c-list-title">問い合わせ</span>
      </a>
    </li>
    <li class="l-header__sns">
      <a href="https://www.instagram.com/emu.house_2023/" target="_brank">
        <img src="./../../../../assets/img/instagram.png" alt="Instagram" />
      </a>
      <a
        href="https://www.facebook.com/people/%E9%87%8D%E5%BA%A6%E9%9A%9C%E3%81%8C%E3%81%84%E8%80%85%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%83%9B%E3%83%BC%E3%83%A0%E7%AC%91%E6%81%B5/100084292812973/"
        target="_brank"
      >
        <img src="./../../../../assets/img/facebook.png" alt="Facebook" />
      </a>
    </li>
  </ul>
  <div class="l-header__menu" *ngIf="!isHumberger">
    <img
      src="./../../../../assets/img/menu.png"
      alt="メニュー"
      (click)="onHumberger()"
    />
  </div>
  <div class="l-header__menu" *ngIf="isHumberger">
    <img
      src="./../../../../assets/img/close.png"
      alt="メニュー"
      (click)="onHumberger()"
    />
  </div>
  <div class="l-header__panel" *ngIf="isHumberger">
    <ul>
      <li>
        <a href="/">トップページ </a>
      </li>
      <li>
        <a href="/facility">施設案内</a>
      </li>
      <!-- <li>
        <a href="/blog-list">ブログ</a>
      </li> -->
      <li>
        <a href="/about">会社概要</a>
      </li>

      <li>
        <a class="c-contact --sp" href="/contact"
          >お問い合わせ
          <img src="./../../../../assets/img/mail.png" alt="お問い合わせ" />
        </a>
      </li>
    </ul>

    <div class="l-header__panel__top">
      <div class="l-header__panel__top__contact">
        <span class="l-header__panel__top__contact__tel">
          <img src="./../../../../assets/img/move-tel.png" alt="" />
          072-451-2050</span
        >
        <span>（受付時間 9：00～18：00）</span>
        <span class="l-header__panel__top__contact__fax">FAX 072-451-2055</span>
      </div>
      <img
        class="c-pc"
        alt="日中サービス支援型 重度障がい者グループホーム笑恵"
        src="./../../../../assets/img/logo-header.png"
        oncontextmenu="return false;"
      />
      <img
        class="c-sp"
        alt="日中サービス支援型 重度障がい者グループホーム笑恵"
        src="./../../../../assets/img/logo-footer-sp.png"
        oncontextmenu="return false;"
      />
    </div>
  </div>
</div>
