import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { text } from 'body-parser';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content: '',
    });
    this.title.setTitle('日中サービス支援型 重度障がい者グループホーム笑恵');
  }

  public headerClass = 'c-header-transparent';
  public scllor = '';

  public thumbnail = 'eat1.png';
  public selectEatContes = 'ハンバーグステーキ<br>サラダ<br>スープ';

  public options = [
    { img: 'eat1.png', text: 'ハンバーグステーキ<br>サラダ<br>スープ' },
    { img: 'eat2.png', text: 'ペペロンチーノ<br>オムレツ<br>スープ' },
    { img: 'eat3.png', text: 'カレーライス<br>ナゲット。' },
    {
      img: 'eat4.png',
      text: 'トマトとツナのカッペリーニ風<br>洋風おにぎり',
    },
    { img: 'eat5.png', text: '鶏肉のポトフ<br>サラダ<br>サラダ' },
    {
      img: 'eat6.png',
      text: 'イングリッシュマフィンバーガー<br>バターコーン<br>じゃがバター<br>フレッシュトマト。 ',
    },
    {
      img: 'eat7.png',
      text: '一口にゅうめん<br>彩りおにぎり',
    },
  ];
  @HostListener('window:scroll', []) onWindowScroll() {
    if (window.pageYOffset) {
      this.headerClass = '';
      this.scllor = '';
    } else {
      this.headerClass = 'c-header-transparent';
      this.scllor = 'scllor';
    }
  }

  public onAccept(option: any) {
    this.thumbnail = option.img;
    this.selectEatContes = option.text;
  }
}
