<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-wrapper__main">
    <div class="l-about">
      <div class="l-about__title">
        <app-title label="会社概要"></app-title>
      </div>

      <div class="l-about__thought">
        <div class="l-about__thought__title">
          <h2 class="l-about__thought__title__text">
            <img src="./../../../../assets/img/happa.png" alt="葉っぱ" />
            私たちの想い
          </h2>
        </div>
        <div class="l-about__thought__description">
          <span
            >私たちがグループホームを立ち上げた理由は、看護師としての経験を活かし</span
          >
          <span>地域の障がい者の方々やリハビリテーションが必要な方々が</span>
          <span>安心して暮らせるようサポートしたいという想いからです。</span>
          <span>医療的ニーズの必要な方、在宅での生活が難しくなってきた方</span>
          <span>が毎日楽しく笑顔で過ごせるように色々な角度で対応し、</span>
          <span
            >一人一人に応じた個別プランを作成して継続的なサポートを行います。</span
          >
          <span
            >私たちは、利用者一人ひとりのニーズに応じた個別ケアプランを作成し、</span
          >
          <span>継続的なサポートを行います。</span>
          <span
            >また、ご家族や地域と連携し、利用者が安心して地域で暮らしが</span
          >
          <span>続けられるよう、総合的な支援を目指しています。</span>
          <span
            >私たちのグループホームは皆様の笑顔と安心を実現するため、日々努力し続けます。</span
          >
        </div>
      </div>

      <div class="l-about__company">
        <span>名称</span>
        <span>株式会社アイズ</span>

        <span>設立</span>
        <span>2016年10月</span>

        <span>資本金</span>
        <span>1,000,000円</span>

        <span>代表者</span>
        <span>松本　由美</span>

        <span>本社所在地</span>
        <span>〒546-0044 大阪府大阪市東住吉区北田辺2-3-9 1 F</span>

        <span>電話番号</span>
        <span>06-4305-7195</span>

        <span>事業内容</span>
        <span>訪問看護事業</span>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
