<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-wrapper__main">
    <div class="l-first-view">
      <img
        class="c-pc"
        src="./../../../../assets/img/first-view_old.png"
        alt="グループホーム笑恵で笑顔に恵まれる暮らしを"
      />
      <img
        class="c-sp"
        src="./../../../../assets/img/first-view-sp_old.png"
        alt="グループホーム笑恵で笑顔に恵まれる暮らしを"
      />
      <div class="l-first-view__context">
        <img
          src="./../../../../assets/img/left-tree.png"
          class="l-first-view__context__left-tree c-pc"
          alt="左の木"
        />
        <img
          src="./../../../../assets/img/left-tree-sp.png"
          class="l-first-view__context__left-tree c-sp"
          alt="左の木"
        />
        <h2 class="l-first-view__context__title">
          <img src="./../../../../assets/img/happa.png" alt="葉っぱ" />
          日中サービス支援型とは？
        </h2>
        <div class="l-first-view__context__description">
          <span>日中サービス支援型とは、昼夜問わず利用者様の状況や</span>
          <span>体調に応じたサービスを行う支援のことです。</span>
          <br /><br />
          <span>笑恵では介護スタッフをはじめ、訪問看護師が在籍しており</span>
          <span>皆様の体調管理や日常生活の支援、また毎日を楽しく過ごして</span>
          <span>いただけるようにレクレーションも用意しています。</span>
          <span>そして大切な家族の一員でもあるペットを居室で愛でることも</span>
          <span>できます。</span>
          <span
            >利用者様やご家族様、また働くスタッフが笑顔に恵まれ、24時間</span
          >
          <img
            src="./../../../../assets/img/right-tree.png"
            class="l-first-view__context__right-tree c-pc"
            alt="右の木"
          />
          <img
            src="./../../../../assets/img/right-tree-sp.png"
            class="l-first-view__context__right-tree c-sp"
            alt="右の木"
          />

          <span>365日幸せを感じていただける施設です。</span>
        </div>
      </div>
    </div>
    <div class="l-living-view">
      <div class="l-living-view__title">
        <h2 class="l-living-view__title__text">
          <img
            class="c-pc"
            src="./../../../../assets/img/happa.png"
            alt="葉っぱ"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/happa-sp.png"
            alt="葉っぱ"
          />
          笑恵での暮らし
        </h2>
      </div>
      <div class="l-living-view__description">
        <img
          class="c-pc"
          src="./../../../../assets/img/living-1.png"
          alt="イヌ"
        />
        <img
          class="c-sp"
          src="./../../../../assets/img/living-1-sp.png"
          alt="イヌ"
        />
        <div class="l-living-view__description__text">
          <span class="l-living-view__description__text__title"
            >ペットと一緒に癒しの生活を
          </span>
          <img src="./../../../../assets/img/border1.png" alt="線" />
          <span>大切な家族であるペットも居室にて </span>
          <span>一緒に過ごすことができます。</span>
          <span class="l-living-view__description__text__attention"
            >※マナーウェアの着用をお願いしております。</span
          >
        </div>
        <div></div>
        <div></div>
        <img
          class="c-sp"
          src="./../../../../assets/img/living-2-sp.png"
          alt="看護師"
        />

        <div class="l-living-view__description__text">
          <span class="l-living-view__description__text__title"
            >訪問看護師が在籍しているので安心
          </span>
          <img src="./../../../../assets/img/border2.png" alt="線" />
          <span>経験豊富な看護師が在籍しています。</span>
          <span>医療的なケアや、リハビリテーション</span>
          <span>をサポートします。</span>
        </div>
        <img
          class="c-pc"
          src="./../../../../assets/img/living-2.png"
          alt="看護師"
        />
        <img
          class="c-pc"
          src="./../../../../assets/img/living-3.png"
          alt="浴槽"
        />
        <img
          class="c-sp"
          src="./../../../../assets/img/living-3.png"
          alt="浴槽"
        />
        <div class="l-living-view__description__text">
          <span class="l-living-view__description__text__title"
            >特殊浴槽を完備しています
          </span>
          <img src="./../../../../assets/img/border1.png" alt="線" />
          <span>寝ながらでも入浴可能な特殊浴槽 </span>
          <span>で入浴の介助をします。</span>
        </div>
        <div></div>
        <div></div>
        <img
          class="c-sp"
          src="./../../../../assets/img/living-4-sp.png"
          alt="ウッドデッキ"
        />
        <div class="l-living-view__description__text">
          <span class="l-living-view__description__text__title"
            >自然を感じられるウッドデッキ
          </span>
          <img src="./../../../../assets/img/border2.png" alt="線" />
          <span>ウッドデッキのバルコニーで外の空気を </span>
          <span>吸いながら休憩できます。</span>
        </div>
        <img
          class="c-pc"
          src="./../../../../assets/img/living-4.png"
          alt="ウッドデッキ"
        />
        <img
          class="c-pc"
          src="./../../../../assets/img/living-5.png"
          alt="家庭菜園"
        />
        <img
          class="c-sp"
          src="./../../../../assets/img/living-5-sp.png"
          alt="家庭菜園"
        />
        <div class="l-living-view__description__text">
          <span class="l-living-view__description__text__title"
            >家庭菜園を楽しむ
          </span>
          <img src="./../../../../assets/img/border1.png" alt="線" />
          <span>施設内のミニ庭園では、家庭菜園 </span>
          <span> を楽しむこともできます。</span>
        </div>
        <div></div>
      </div>
    </div>
    <div class="l-guidance-view">
      <div class="l-guidance-view__title">
        <h2 class="l-guidance-view__title__text">
          <img
            class="c-pc"
            src="./../../../../assets/img/happa.png"
            alt="葉っぱ"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/happa-sp.png"
            alt="葉っぱ"
          />
          ご利用案内
        </h2>
      </div>
      <div class="l-guidance-view__description">
        <span class="l-guidance-view__description__title">
          ご利用いただける方
        </span>
        <ul>
          <li>・18歳から65歳未満の身体重度をお持ちの方</li>
          <li>・支援区分認定を受けている方</li>
        </ul>
        <span class="l-guidance-view__description__title"> 申し込み方法 </span>
        <div>グループホーム笑恵に直接お申し込みください。</div>
        <div>
          また、現在ご利用されている相談支援事業所の支援相談員にご相談ください。
        </div>
      </div>
      <div class="l-guidance-view__grid">
        <span class="l-guidance-view__grid__title">ご利用料金表</span>
        <span class="l-guidance-view__grid__sub-title"
          >家賃補助適用ありの場合</span
        >
        <div class="l-guidance-view__grid__table">
          <span>家賃</span>
          <span>30,000円</span>

          <span>水道光熱費</span>
          <span>25,000円</span>

          <span>食費</span>
          <span>45,000円</span>

          <span>日用品費</span>
          <span>6,000円</span>

          <!-- <span>修繕積立費</span>
          <span>6,000円</span> -->

          <span
            >合計<br class="c-sp" />
            <div class="--end">（お支払いの目安）</div></span
          >
          <span>106,000円 /月</span>
        </div>
        <span class="l-guidance-view__grid__attention">
          ※おむつ代は別途実費
        </span>
        <span class="l-guidance-view__grid__sub-title"
          >家賃補助適用なしの場合</span
        >
        <div class="l-guidance-view__grid__table">
          <span>家賃</span>
          <span>40,000円</span>

          <span>水道光熱費</span>
          <span>25,000円</span>

          <span>食費</span>
          <span>45,000円</span>

          <span>日用品費</span>
          <span>6,000円</span>
          <!--
          <span>修繕積立費</span>
          <span>5,000円</span> -->

          <span
            >合計<br class="c-sp" />
            <div class="--end">（お支払いの目安）</div></span
          >
          <span>116,000円 /月</span>
        </div>
        <span class="l-guidance-view__grid__attention">
          ※おむつ代は別途実費
        </span>
      </div>

      <span class="l-guidance-view__grid__other">
        その他、必要時訪問看護による健康管理費自己負担分、薬代は別途かかります。
      </span>

      <span class="l-guidance-view__grid__detail">
        <a href="/facility">
          くわしくみる
          <img src="./../../../../assets/img/guidance_next.png" alt="next" />
        </a>
      </span>
    </div>

    <div class="l-flow-view">
      <div class="l-flow-view__title">
        <h2 class="l-flow-view__title__text">
          <img
            class="c-pc"
            src="./../../../../assets/img/happa.png"
            alt="葉っぱ"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/happa-sp.png"
            alt="葉っぱ"
          />
          食事内容
        </h2>
      </div>
      <div class="l-flow-view__description">
        <div style="display: flex; justify-content: center; gap: 12px">
          <img
            class="c-pc"
            [src]="'./../../../../assets/img/' + thumbnail"
            alt=""
          />
          <img
            class="c-sp"
            [src]="'./../../../../assets/img/' + thumbnail"
            alt=""
          />
          <div>
            <h3 style="font-weight: 700; font-size: 18px">食事内容</h3>
            <div [innerHTML]="selectEatContes"></div>
          </div>
        </div>
        <div
          style="
            display: flex;
            padding-top: 32px;
            gap: 12px;
            overflow-x: scroll;
          "
        >
          <ng-container *ngFor="let option of options">
            <img
              class="c-pc"
              [src]="'./../../../../assets/img/' + option.img"
              alt=""
              (click)="onAccept(option)"
            />
            <img
              class="c-sp"
              [src]="'./../../../../assets/img/' + option.img"
              alt=""
              (click)="onAccept(option)"
            />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="l-flow-view">
      <div class="l-flow-view__title">
        <h2 class="l-flow-view__title__text">
          <img
            class="c-pc"
            src="./../../../../assets/img/happa.png"
            alt="葉っぱ"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/happa-sp.png"
            alt="葉っぱ"
          />
          入居までの流れ
        </h2>
      </div>
      <div class="l-flow-view__description">
        <img class="c-pc" src="./../../../../assets/img/flow.png" alt="" />
        <img class="c-sp" src="./../../../../assets/img/flow-sp.png" alt="" />
      </div>
    </div>

    <div class="l-map-view">
      <div class="l-map-view__title">
        <h2 class="l-map-view__title__text">
          <img
            class="c-pc"
            src="./../../../../assets/img/happa.png"
            alt="葉っぱ"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/happa-sp.png"
            alt="葉っぱ"
          />
          アクセス
        </h2>
      </div>
      <div class="l-map-view__description">
        <img class="c-pc" src="./../../../../assets/img/map.png" alt="" />
        <img class="c-sp" src="./../../../../assets/img/map-sp.png" alt="" />
        <div class="l-map-view__description__address">
          <span class="l-map-view__description__address__title"
            >〒590-0451</span
          >
          <span class="l-map-view__description__address__title"
            >大阪府泉南部熊取町野田1丁目15-4</span
          >
          <span class="l-map-view__description__address__move"
            >熊取駅より車で約８分／熊取町役場から徒歩６分</span
          >
          <div class="l-map-view__description__address__contact">
            <span class="l-map-view__description__address__contact__tel">
              <img src="./../../../../assets/img/move-tel.png" alt="" />
              072-451-2050</span
            >
            <span>（受付時間 9：00～18：00）</span>
          </div>

          <div class="l-map-view__description__address__other">
            <span>送迎は行っておりません。</span>
            <span>介護タクシーなどを利用する場合は利用者様の</span>
            <span>自己負担になりますのでご了承ください。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="l-google-map-view">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d692.0803021819845!2d135.35815255474807!3d34.39861909528642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000c9224be5bd9f%3A0x555200b9a431bfc5!2z44CSNTkwLTA0NTEg5aSn6Ziq5bqc5rOJ5Y2X6YOh54aK5Y-W55S66YeO55Sw77yR5LiB55uu77yR77yV4oiS77yT!5e0!3m2!1sja!2sjp!4v1680888752026!5m2!1sja!2sjp"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
  <app-footer></app-footer>
</div>
